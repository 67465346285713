<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'保养单'" />

    <span v-if="detailData.pdf_file">PDF加载可能较慢,请等待...</span>
    <span v-else>暂无保养单</span>
    <div class="printBox">
      <embed 
      id="printMe"
      :src="detailData.pdf_file"
      type="application/pdf"
      width="100%"
      height="800px" />
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import { getBase64 } from '@/utils/common'
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'MaintaintaskDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      detailId: '',
      dataList: [
        {
          name: '医院',
          value: '',
          width: '25%',
        },
        {
          name: '机号',
          value: '',
          width: '25%',
        },
        {
          name: '仪器型号',
          value: '',
          width: '25%',
        },
        {
          name: '仪器位置',
          value: '',
          width: '25%',
          rightBorder: false,
        },
        {
          name: '经销商',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
        {
          name: '售后人员',
          value: '',
          width: '25%',
          bottomBorder: false,
        },
      ],
      detailData: {},
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitmaintaintaskDetail()
  },
  methods: {
    gitmaintaintaskDetail() {
      apiDetail.maintaintaskDetail({ task_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.dataList[0].value = this.detailData.hospital_name
        this.dataList[1].value = this.detailData.instrument_id
        this.dataList[2].value = this.detailData.platform_unique
        this.dataList[3].value = this.detailData.hos_pos_name
        this.dataList[4].value = this.detailData.customer_name
        this.dataList[5].value = this.detailData.engineer_name
      })
    },
    download() {
      let _this = this
      if(_this.detailData.pdf_file == '' || _this.detailData.pdf_file == null){
        this.$message.warning('下载路径为空！')
        return
      }
      getBase64(_this.detailData.pdf_file, (url) => {
        var imageData = url
        var doc = new jsPDF('landscape', 'pt', [205, 115])
        doc.addImage(imageData, 'PNG', 0, 0, 205, 115)
        doc.save('a4.pdf')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.printBox{
  margin-top: px(20);
}
</style>
